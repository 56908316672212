<template>
  <div class="dashboard">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-card @click="fetchActiveCoupons">
            <div class="actv-coupon">Active Coupons</div>
            <v-card-text class="actv-count">
              {{ activeCount }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card @click="fetchExpiredCoupons">
            <div class="actv-coupon">Expired Coupons</div>
            <v-card-text class="actv-count">
              {{ expiredCount }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-btn
          color="primary"
          dark
          class="create-btn"
          @click="openCreateDialog"
        >
          Create
        </v-btn>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>COUPONS</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:[`item.sno`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    style="
                      border-radius: 1px;
                      border-color: #0a5b8a;
                      border-style: solid;
                      border-radius: 36px;
                    "
                    small
                    class="ml-2"
                    @click.stop="viewItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    v-if="item.redeemedUsersCount > 0"
                  >
                    <v-icon color="#0A5B8A" style="margin-top: 2px" size="18">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                View Item
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="ml-2"
                    style="
                      border-radius: 1px;
                      border-color: #0a5b8a;
                      border-style: solid;
                      border-radius: 36px;
                    "
                    small
                    @click.stop="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#0A5B8A" style="margin-top: 2px" size="18"
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                </template>
                Edit Item
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="ml-2"
                    style="
                      border-radius: 1px;
                      border-color: #0a5b8a;
                      border-style: solid;
                      border-radius: 36px;
                    "
                    small
                    @click.stop="deleteItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#0A5B8A" style="margin-top: 2px" size="18"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </template>
                Delete Item
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <create-awards
        :dialog="createDialog"
        :onClose="onCloseDialog"
        v-if="createDialog"
      />
      <edit-awards
        :dialog="editDialog"
        :onClose="onCloseDialog"
        :item="editedItem"
        v-if="editDialog"
      />
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseDialog"
        :onConfirm="onDeleteAward"
      />
      <coupons-view-dialog
        :viewDialog="viewDialog"
        :onClose="onCloseDialog"
        :item="viewedItem"
        :itemId="viewedItem._id"
        v-if="viewDialog"
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CreateAwards from "../../awards/CreateAwards.vue";
import EditAwards from "../../awards/EditAwards.vue";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import CouponsViewDialog from "../../awards/CouponsViewDialog.vue";

export default {
  name: "Dashboard",
  components: {
    CreateAwards,
    EditAwards,
    CouponsViewDialog,
    ConfirmDeleteDialog,
  },
  data() {
    return {
      headers: [
        { text: "S.No", value: "sno", sortable: false },
        { text: "Coupon Title", value: "title" },
        { text: "Coupon Code", value: "couponCode" },
        { text: "Valid Till", value: "formattedExpiryDate" },
        { text: "Available Counts", value: "count" },
        { text: "Redeemed Counts", value: "redeemedUsersCount" },
        { text: "Actions", value: "actions" },
      ],
      search: "",
      createDialog: false,
      editDialog: false,
      viewDialog: false,
      deleteDialog: false,
      editedItem: {},
      viewedItem: {},
      award: null,
      currentFilter: "all",
      activeCount: 0,
      expiredCount: 0,
    };
  },
  computed: {
    ...mapGetters("awards", ["getAllAwards"]),
    filteredItems() {
      return this.getAllAwards
        .filter(
          (item) =>
            this.currentFilter === "all" || item.status === this.currentFilter
        )
        .map((item) => ({
          ...item,
          formattedExpiryDate: this.formatDate(item.expiryDate),
        }));
    },
  },
  methods: {
    ...mapActions("awards", [
      "fetchAllAwards",
      "getCopuonCounts",
      "deleteAward",
    ]),
    async fetchActiveCoupons() {
      const payload = { type: "active" };
      const awards = await this.fetchAllAwards(payload);
      this.updateStatus(awards, "active");
      this.currentFilter = "active";
    },
    async fetchExpiredCoupons() {
      const payload = { type: "expired" };
      const awards = await this.fetchAllAwards(payload);
      this.updateStatus(awards, "expired");
      this.currentFilter = "expired";
    },
    updateStatus(awards, status) {
      awards.forEach((award) => {
        award.status = status;
      });
    },
    openCreateDialog() {
      this.createDialog = true;
    },
    onCloseDialog() {
      if (!this.createDialog || !this.editDialog ) {
        this.loadCouponCounts();
      }
      this.createDialog = false;
      this.editDialog = false;
      this.deleteDialog = false;
      this.viewDialog = false;
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.editDialog = true;
    },
    viewItem(item) {
      this.viewedItem = { ...item };
      this.viewDialog = true;
    },
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    deleteItem(item) {
      this.award = item;
      this.deleteDialog = true;
    },
    async onDeleteAward() {
      await this.deleteAward({ _id: this.award._id });
      this.deleteDialog = false;
      this.loadCouponCounts();
    },
    async loadCouponCounts() {
      try {
        const counts = await this.getCopuonCounts();
        this.activeCount = counts.activeCount || 0; 
        this.expiredCount = counts.inactiveCount || 0;
      } catch (error) {
        console.error("Failed to load coupon counts:", error);
      }
    },
  },
  async created() {
    await this.fetchAllAwards();
    await this.loadCouponCounts();
  },
};
</script>

<style scoped>
.dashboard {
  padding: 20px;
}
.create-btn {
  margin-left: 90%;
}
.actv-coupon {
  font-size: 20px;
  font-weight: bold;
  color: #0a5b8a;
  padding: 15px 11px 3px 11px;
}
.actv-count {
  font-size: 30px;
  font-weight: bold;
  color: #0a5b8a;
   padding: 0px 11px 15px 11px;
}
</style>
