<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title class="d-flex flex-row justify-between app-bold-font">
        Create New Award
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <div style="min-height: 500px; max-height: 61vh; overflow: auto">
        <v-card-text class="app-regular-font">
          Add the awards details your volunteer can get.
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column py-0">
          <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
            Title
          </div>
          <v-text-field
            label="title"
            solo
            flat
            outlined
            class="mt-2 app-regular-font"
            v-model="award.title"
            :rules="[rules.required]"
            dense
          />
        </v-card-text>
        <v-card-text
          class="d-flex py-0"
          :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
        >
          <div>
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              Goal hours
            </div>
            <div
              :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'"
            >
              <v-text-field
                label="Ex: 120"
                solo
                flat
                outlined
                dense
                class="mt-2 app-regular-font"
                v-model="award.goal_hours"
                type="number"
                :rules="[rules.required]"
                :min="0"
              />
            </div>
          <!-- <p v-if="list.length > 0">Eligible</p> -->
            <!-- <v-combobox
            v-if="list.length > 0"
            v-model="award.eligible"
            :items="list"
            item-text="first_name"
            item-value="_id"
            label="Eligible"
            solo
            flat
            outlined
            dense
            class="mt-2 app-regular-font"
            multiple
            :multiple-limit="list.length"
            /> -->
          </div>
          <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-3'">
            <div class="app-medium-font dark-font-color">
              Expiry Date
            </div>
            <div
              class="d-flex mt-2"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <div
                :style="
                  $vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'
                "
              >
                <v-menu
                  v-model="date_picker"
                  :close-on-content-click="false"
                  :return-value.sync="awardDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :top="$vuetify.breakpoint.xs"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="award.expiryDate"
                      label="MM/DD/YYYY"
                      solo
                      flat
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      autocomplete="off"
                      :style="
                        $vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'
                      "
                    />
                  </template>
                    <v-date-picker 
                    v-model="awardDate" 
                    no-title 
                    scrollable 
                    :min="new Date().toISOString().substr(0, 10)"
                    >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="date_picker = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="onSaveDate(awardDate)">
                      OK
                    </v-btn>
                    </v-date-picker>
                </v-menu>
              </div>
              <!-- <div :style="$vuetify.breakpoint.xs ? 'width: 100%' : ''">
                <vue-timepicker
                  format="hh:mm a"
                  v-model="awardTime"
                  :minute-interval="5"
                  :class="$vuetify.breakpoint.xs ? 'w-100 mb-5' : 'ml-3'"
                />
              </div> -->
            </div>
          </div>
        </v-card-text>
        <!-- <v-card-text class="d-flex justify-center flex-column py-0">
          <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
            Items
          </div>
          <div class="d-flex mt-2">
            <div
              :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'"
            >
              <v-text-field
                label="Name"
                solo
                flat
                outlined
                v-model="newName"
                style="font-family:'Poppins-Regular';"
                dense
              />
            </div>
            <div
              :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'"
              class="ml-3"
            >
              <v-text-field
                label="Unit"
                solo
                flat
                outlined
                v-model="newUnit"
                style="font-family:'Poppins-Regular';"
                dense
                type="number"
              />
            </div>
            <div>
              <v-btn
                @click="onAddItem"
                v-text="'Add Item'"
                color="#b5e539"
                class="white--text ml-3"
                :disabled="!newName"
              />
            </div>
          </div>
          <div v-if="award.items" class="mb-3">
            <div v-for="item in award.items" :key="item.name" class="ml-3">
              {{ item.name }}{{ item.unit ? " --- " + item.unit : "" }}
              <v-btn icon @click="onDeleteItem(item)" class="ml-5"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </div>
          </div>
        </v-card-text> -->

        <v-card-text class="d-flex justify-center flex-column py-0">
          <div class="app-medium-font dark-font-color">
            Terms & Conditions
          </div>
          <v-textarea
            rows="4"
            auto-grow
            label="Terms & Conditions"
            solo
            flat
            outlined
            class="mt-2 app-regular-font"
            v-model="award.description"
            :rules="[rules.required]"
          />
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column py-0">
          <div class="app-medium-font dark-font-color">Coupon Counts</div>
          <v-text-field
                label="0"
                solo
                flat
                outlined
                v-model="award.couponCounts"
                type="number"
                style="font-family:'Poppins-Regular';"
                dense
                :min="0"
              />
        </v-card-text>
         <v-card-text class="d-flex justify-center flex-column py-0">
          <div class="app-medium-font dark-font-color">Coupon Code</div>
            <v-text-field
              label="CODE123"
              solo
              flat
              outlined
              v-model="award.couponCode"
              style="font-family:'Poppins-Regular';"
              dense
              @input="award.couponCode = award.couponCode.toUpperCase()"
              />
        </v-card-text>
        <!-- <v-card-text
          class="d-flex py-0"
          :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
        >
          <div style="flex: 1;">
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              Award Counts
            </div>
            <div :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'">
              <v-text-field
                label="Ex: 120"
                solo
                flat
                outlined
                dense
                class="mt-2 app-regular-font"
                v-model="award.couponCounts"
                type="number"
                :rules="[rules.required]"
                @input="updateCouponCodes"
              />
            </div>
          </div>
          <div style="flex: 1;">
            <div class="app-medium-font dark-font-color">
              Award Type
            </div>
            <v-radio-group v-model="award.type" class="mt-2 app-regular-font" @change="updateMethod">
              <v-radio label="manual" value="Manual"></v-radio>
              <v-radio label="upload" value="Upload"></v-radio>
            </v-radio-group>
          </div>
        </v-card-text>

    Manual Coupon
      <div v-if="methodName === 'Manual'">
      <v-card-text
        v-for="(code, index) in couponCodes"
        :key="index"
        class="d-flex py-0"
        :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
      >
        <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-3'" style="width: 50%;" >
          <div class="app-medium-font dark-font-color">
            Coupon Code {{ index + 1 }}
          </div>
          <div
            class="d-flex"
            :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
          >
            <div
              :style="
                $vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'
              "
            >
              <v-text-field
                :label="'Ex: CODE' + (index + 1)"
                solo
                flat
                outlined
                dense
                class="mt-2 app-regular-font"
                v-model="couponCodes[index]"
                type="text"
                @input="couponCodes[index] = couponCodes[index].toUpperCase()"
              />
            </div>
          </div>
        </div>
      </v-card-text>
      </div>

     Upload through CSV 
      <v-card-text class="d-flex justify-center flex-column py-0" v-if="methodName === 'Upload'">
        <div class="app-medium-font dark-font-color">
          Upload CSV/XLSX
        </div>
        <v-file-input
          label="Browse"
          solo
          flat
          outlined
          dense
          class="mt-2 app-regular-font"
          accept=".csv, .xlsx"
          @change="onFileChange"
        />
      </v-card-text> -->

        <v-card-text v-if="message">
          <v-alert dense type="error" v-text="message" v-if="message" />
        </v-card-text>
      </div>
      <v-card-actions class="pb-6 mt-3 d-flex flex-row justify-center">
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Create</v-btn
        >
      </v-card-actions>
        <v-snackbar v-model="snackbar" :timeout="3000">
        {{ message }}
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
// import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import * as XLSX from 'xlsx';
export default {
  components: { 
    // VueTimepicker

   },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function
    },
    eligibleList: {
      type: Array
    }
  },
  data() {
    return {
      message: null,
      loading: false,
      rules: {
        required: value => !!value || "Required."
      },
      award: {
        description: '',
        couponCounts: 0,
        goal_hours: 0, 
        couponCount: 0,
        couponCode: '',
      },
      couponCodes: [],
      date_picker: false,
      awardDate: null,
      awardTime: null,
      newName: null,
      newUnit: null,
      snackbar: false,
      list: [],
      methodName: null
    };
  },
    computed: {
    ...mapState("auth", {
      profile: "profile"
    }),
    coupoNCodes() {
      const length = this.award.couponCounts;
      return Array.from({ length }, (_, i) => this.couponCodes[i] || '');
    }
  },
    watch: {
    'award.goal_hours': 'updateCouponCodes',
    'award.couponCounts': 'updateCouponCodes'
  },
  methods: {
    ...mapActions("awards", {
      addAward: "addAward",
      getEligibles: "getEligibles"
    }),
    updateCouponCodes() {
      const length =  this.award.couponCounts;
      this.couponCodes = Array.from({ length }, (_, i) => this.couponCodes[i] || '');
    },
    onChange(i) {
      this.message = null;
      this.list= this.eligibleList?.filter(e =>  e.volunteered_hours >= i);
    },
    updateMethod(value) {
      this.methodName = value;
    },
   onFileChange(event) {
      const file = event;
      if (file) {
        console.log(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        this.couponCodes = jsonData.flat();
      };
      reader.readAsArrayBuffer(file);
      } else {
        console.error("File input event is not valid.");
      }
    },
    onSave() {
      if (!this.award.title) {
        this.message = "Please add title.";
        return;
      }
      if (!this.award.goal_hours) {
        this.message = "Please add goal hours.";
        return;
      }
      // if (!this.couponCodes || this.couponCodes.length === 0) {
      //   this.message = "Please add Coupon Codes.";
      //   return;
      // }
      if (!this.award.couponCode) {
        this.message = "Please add Coupon Code.";
        return;
      }
      if (!this.award.couponCounts) {
        this.message = "Please add Coupon Counts.";
        return;
      }
      if (!this.award.expiryDate) {
        this.message = "Please select thge Expiry Date.";
        return;
      }
        
      var params = {
        ...this.award
      };
      console.log(params,'4w5r98as');
      
      this.loading = true;
      this.addAward(params)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch(error => {
          this.loading = false;
          this.snackbar = true;
          this.message = error;
        });
    },
    changeDateFormat(dateStr) {
      console.log(dateStr.substring(5, 7));
      return `${dateStr.substring(5, 7)}/${dateStr.substring(
        8,
        10
      )}/${dateStr.substring(0, 4)}`;
    },
    onSaveDate(dateStr) {
      this.award.expiryDate = this.changeDateFormat(dateStr);
      this.date_picker = false;
    },
    onAddItem() {
      if (!this.award.items) {
        this.award.items = [];
      }
      this.award.items = this.award.items.filter(a => a.name != this.newName);
      this.award.items.push({ name: this.newName, unit: this.newUnit });
      this.newName = "";
      this.newUnit = "";
    },
    onDeleteItem(item) {
      this.award.items = this.award.items.filter(a => a.name != item.name);
      this.award = { ...this.award };
    },
        loadData() {
      this.loading = true;
        this.getEligibles()
          .then(res => {
            this.eligibles = res;
          })
          .catch(error => {
            console.log(error);
          });
    },
    mounted() {
      this.loadData();
    }
  },


};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
    /* padding-top: 17px; */
    /* padding-bottom: 17px; */
    /* height: 100%; */
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div :deep(.v-dialog) {
    /* height: 80vh; */
    width: 70%;
    max-width: 900px;
    overflow-y: hidden;
  }
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.time-picker {
  width: 107px;
  border-color: #d094ea !important;
  border-width: 1px;
  height: 40px;
}
.time-picker :deep(input.display-time) {
  height: 40px !important;
  width: 100%;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.38);
  padding: 0 15px;
}
.time-picker :deep(input.display-time:hover) {
  border-color: currentColor;
}
.time-picker :deep(input.display-time:focus-visible) {
  border-color: #d094ea !important;
  border-width: 1px;
}
.time-picker :deep(.clear-btn) {
  padding: 5px 16px;
  margin-top: 5px;
}
</style>
