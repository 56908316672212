<template>
    <v-dialog v-model="viewDialog" persistent max-width="800px">
        <v-card>
            <v-card-title class="flex-row justify-between d-flex app-bold-font">
                Redeemed Users
                <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>

            <!-- Search Bar -->
            <v-card-text>
                <v-text-field
                    v-model="search"
                    label="Search Users"
                    prepend-icon="mdi-magnify"
                    dense
                    outlined
                    class="mb-4"
                />

                <!-- Data Table -->
                <v-data-table 
                    :headers="headers"
                    :items="filteredUsers"
                    class="elevation-1"
                    item-value="name"
                >
                    <template v-slot:[`item.sno`]="{ index }">
                        {{ index + 1 }}
                    </template>
                     <template v-slot:[`item.actions`]="{ item }">
                        <v-checkbox v-model="item.used" :true-value="true" :false-value="false" :disabled="item.used" @change="updateUserStatus(item)"></v-checkbox>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
            <v-snackbar v-model="snackbar">
      {{ sucessMessage }}
    </v-snackbar>
    </v-dialog>
    
</template>

<script>
import { mapActions,mapGetters } from "vuex";

export default {
    props: {
        viewDialog: {
            type: Boolean,
            default: false
        },
        couponWinners: {
            type: Array,
            default: () => []
        },
        onClose: {
            type: Function,
            required: true
        },
        item: Object,
        itemId: String
    },
    data() {
        return {
            search: '',
            headers: [
                { text: "S.No", value: "sno", sortable: false },
                { text: "Name", value: "name" },
                { text: "Email", value: "email" },
                { text: "Reedemed Date", value: "reedemed_date" },
                { text: "Actions", value: 'actions', sortable: false }
            ],
            users: [],
            snackbar:false,
            sucessMessage:''
        };
    },
    computed: {
        ...mapGetters("awards", {
            reedemedUsers: "getReedemedUsers"
        }),
    filteredUsers() {
        if (!Array.isArray(this.reedemedUsers.redeemedUsers)) return [];
        
        return this.reedemedUsers.redeemedUsers
            .filter(user => 
                !this.search || 
                user.name.toLowerCase().includes(this.search.toLowerCase()) ||
                user.email.toLowerCase().includes(this.search.toLowerCase())
            )
            .map((user, index) => ({
                ...user,
                sno: index + 1,
                reedemed_date: new Date(user.reedemed_date).toLocaleDateString("en-US"),
                used: user.used  // Ensure this is preserved
            }));
    }
    },
    methods: {
        ...mapActions("awards", {
            getCouponDetails: "getCouponDetails",
            updateCouponCompleted:"updateCouponCompleted"
        }),

        async fetchUsers() {
            const param = {
                couponId: this.itemId
            };

            try {
                const response = await this.getCouponDetails(param);
                this.users = response.redeemedUsers.map(user => {
                    const date = new Date(user.reedemed_date);
                    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
                    return {
                        ...user,
                        reedemed_date: formattedDate
                    };
                });
                
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        },
    formatUser(user, index) {
        let formattedDate = "N/A"; // Default if date is missing or invalid
        if (user.reedemed_date) {
            const date = new Date(user.reedemed_date);
            if (!isNaN(date.getTime())) { // Ensure it's a valid date
                formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/
                                 ${date.getDate().toString().padStart(2, '0')}/
                                 ${date.getFullYear()}`;
            }
        }
        return {
            ...user,
            sno: index + 1,
            reedemed_date: formattedDate
        };
    },
      async updateUserStatus(user) {
        try {
             const payload = {
                award_id: this.itemId, 
                used: user.used,
                userId:user._id
            };

            await this.updateCouponCompleted(payload);
            this.snackbar = true;
            this.sucessMessage = "Coupon Status Updated";
        } catch (error) {
            console.error("Error updating user status:", error);
        }
    }
    },
    watch: {
        viewDialog(newVal) {
            if (newVal) {
                this.fetchUsers();
            }
        }
    },
    mounted(){
        this.fetchUsers();
    }
};
</script>

<style scoped>
.v-data-table {
    max-height: 400px;
    overflow-y: auto;
}
</style>
